import { modulesConfig } from './modules.config';
import { EnumMobileMenuType, EnumSportPageTabs } from '@egamings/sportsbook-core/sportsbook-common';
import { DateFormats } from '@egamings/sportsbook-core';
import { EMarketGroupConfigType, allGroups, asianGroups } from '../../markets';
import { EMarketsCacheGroup } from '@egamings/brsdk';

export const configV2: any = {
    layout: {
        scrollable: false,
    },
    modules: modulesConfig,
    templates: {
        components: {
            sport: {
                template: 'v2:sport',
                params: {
                    marketsTemplate: 'euro',
                }
            },
            sportLive: {
                params: {
                    marketsTemplate: "euro",
                },
            },
            category: {
                template: 'v2:sport',
                params: {
                    marketsTemplate: 'euro'
                    // showTitle: false
                }
            },
            tournament: {
                template: 'v2:tournament',
                params: {
                    marketsTemplate: 'euro',
                    showTournament: false
                }
            },
            live: {
                template: 'v2:sport',
                params: {
                    marketsTemplate: 'euro',
                    showToggler: true,
                    showAllTournaments: true
                }
            },
            topLeagues: {
                template: 'v2:sport',
                params: {
                    marketsTemplate: 'euro'
                }
            },
            allLeagues: 'v2',
            coupon: 'default:coupon',
            gamesPreview: 'default:games-preview',
            game: {
                template: 'v2:game',
                params: {
                    showToggler: false,
                }
            },
            outrights: {
                params: {
                    titleType: 'block-subtitle'
                }
            },
            highlights: {
                template: 'v2:sport',
                params: {
                    marketsTemplate: 'euro'
                }
            },
            upcoming: {
                template: 'v2:sport',
                params: {
                    marketsTemplate: 'euro'
                }
            },
        }
    },
    dateFormat: {
        [DateFormats.sportToday]: 'HH:mm',
        [DateFormats.sportOther]: 'DD MMMM',
        [DateFormats.sportMobile]: 'DD/MM HH:mm',
        [DateFormats.betHistory]: 'DD MMMM HH:mm',
        [DateFormats.todayAndDate]: '[Today] DD MMMM',
        [DateFormats.today]: '[Today]',
        [DateFormats.dayMonth]: 'DD MMMM',
        [DateFormats.hoursMinutes]: 'HH:mm',
        [DateFormats.sportResults]: 'DD MMMM HH:mm',
        [DateFormats.sportResultsShort]: 'DD MMM HH:mm',
        [DateFormats.calendarDayOfWeek]: 'dddd',
        [DateFormats.calendarDayOfMonth]: {
            all: 'DD MMMM',
            ja: 'MMMM DD 日'
        },
        [DateFormats.gameStartTime]: 'LL',
        [DateFormats.topGames]: 'L LT'
    },
    marketGroups: allGroups,
    marketsCacheGroups: {
        [EMarketGroupConfigType.ALL]: EMarketsCacheGroup.main,
    },
    asianMarketGroups: asianGroups,
    asianMarketsCacheGroups: {
        [EMarketGroupConfigType.ALL]: EMarketsCacheGroup.v2,
    },
    euroMarketGroups: allGroups,
    euroMarketsCacheGroups: {
        [EMarketGroupConfigType.ALL]: EMarketsCacheGroup.main,
    },
    liveGames: {
        showGames: true,
        showTournaments: 5,
        hideCount: true,
        mobile: {
            hideCount: true,
            showGames: false,
            showTournaments: 5,
        }
    },
    sportPage: {
        tabsOrder: [
            EnumSportPageTabs.liveGames,
            EnumSportPageTabs.today,
            EnumSportPageTabs.dailyMathList,
            // EnumSportPageTabs.topLeagues,
            EnumSportPageTabs.allLeagues,
            EnumSportPageTabs.outrights,
        ]
    },

    externalComponents: {
        mobileMenu: {
            type: EnumMobileMenuType.coupon,
            styles: {
                background: '#FA387D',
                borderColor: '#fff',
                iconColor: '#fff',
                counterBackground: '#222d39',
                counterColor: '#fff'
            },
        },
        notification: {
            styles: {
                background: '#f3f5f7',
                color: '#000',
                borderRadius: '5px',
                closeButtonColor: '#000',
            }
        }
    },
    svgIcons: {
        mapping: {
            'down-arrow': {
                replaceName: 'down-arrow-v2'
            }
        }
    },
    customRoutes: [
        {
            name: 'special',
            url: '/special'
        }
    ]
};
