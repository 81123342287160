import { EMarketGroupType } from "@egamings/brsdk";
import { allGroups, outcomesHandicap, outcomesTotal, withOutcomeTitles } from "../../markets";
import { modulesConfig } from "./modules.config";

export const configGold: any = {
    theme: 'gold',
    externalComponents: {
        mobileMenu: {
            styles: {
                background:'linear-gradient(90deg, #E6BE02 0.14%, #FF9600 100.14%);',
                borderColor:'none',
                iconColor:'#000',
                counterBackground:'#DD3A3A',
                counterColor:'#fff'
            }
        },
        notification: {
            styles: {
                background: '#202020',
                color: '#fff',
                errorIconColor: '#DD3A3A',
                successIconColor: '#45C195',
                closeButtonColor: '#fff',
                borderRadius: '4px',
            }
        }
    },
    modules: modulesConfig,
    layout: {
        scrollable: true,
        leftSideSub: true,
    },
    svgIcons: {
        mapping: {
            home: {
                replaceName: 'home-v2'
            },
            'down-arrow': {
                replaceName: 'down-arrow-altenar'
            }
        }
    },
    marketGroups: allGroups.map(g => {
        if (g.type === EMarketGroupType.totals) {
            return withOutcomeTitles(g, [''].concat(outcomesTotal));
        } else if (g.type === EMarketGroupType.handicap) {
            return withOutcomeTitles(g, [''].concat(outcomesHandicap));
        }

        return g;
    }),
    marketsViewType: 'row',
    liveGames: {
        showGames: true,
        showTournaments: 5,
        hideCount: true,
        mobile: {
            showGames: false,
            showTournaments: 5,
            hideCount: true,
            hideAllSports: true,
        }
    },
    templates: {
        components: {
            profile: {
                params: {
                    items: [
                        {
                            text: gettext('Open bets'),
                            value: 'profile.open-bets',
                        },
                        {
                            text: gettext('Betting history'),
                            value: 'profile.history',
                        },
                        {
                            text: gettext('Bonuses'),
                            value: 'profile.bonuses',
                        },
                        {
                            text: gettext('Settings'),
                            value: 'profile.settings',
                        },
                    ]
                }
            },
            sport: {
                template: 'altenar:sport',
                params: {
                    dailyMatchList: {
                        hideCalendar: true,
                    },
                    useFilterOnDate: true,
                }
            },
            category: {
                template: 'altenar:sport',
            },
            tournament: {
                template: 'altenar:sport',
                params: {
                    useFilterOnDate: true,
                    showTournament: true,
                    useFilterTabs: true,
                }
            },
            live: {
                template: 'altenar:sport',
                params: {
                    useAccordion: true,
                    useDefaultFirstSport: true,
                    showHeader: true,
                    showSportTabs: true,
                    showTournament: true,
                }
            },
            topLeagues: {
                template: 'altenar:sport',
            },
            allLeagues: 'v2',
            coupon: {
                template: 'altenar:sport',
                params: {
                    useFilterOnDate: true,
                }
            } ,

            gamesPreview: 'default:games-preview',
            game: 'altenar:game',
            outrights: {
                params: {
                    titleType: 'block-subtitle'
                }
            },
            highlights: {
                template: 'altenar:sport',
                params: {
                    useAccordion: true,
                    hideSportSlider: true,
                    showHeader: true,
                    showSportTabs: true,
                    showTournament: false,
                }
            },
            upcoming: {
                template: 'altenar:sport',
                params: {
                    hideCount: true,
                    useAccordion: true,
                    hideCalendar: true,
                    hideSportSlider: true,
                    showHeader: true,
                    showSportTabs: true,
                    showTournament: false,
                }
            },
        }
    },
};
