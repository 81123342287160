import { EMarketGroupType } from "@egamings/brsdk";
import { EMarketGroupConfigType, IMarketGroupConfigExtended } from "./interfaces";

export const soccer = 'sr:sport:1';
export const basketball = 'sr:sport:2';
export const baseball = 'sr:sport:3';
export const tennis = 'sr:sport:5';
export const volleyball = 'sr:sport:23';
export const handball = 'sr:sport:6';
export const iceHockey = 'sr:sport:4';
export const tableTennis = 'sr:sport:20';
export const americanFootball = 'sr:sport:16';
export const rugby = 'sr:sport:12';
export const mma = 'sr:sport:117';
export const cricket = 'sr:sport:21';
// esport
export const counterStrike = 'sr:sport:109';
export const dota = 'sr:sport:111';
export const leagueOfLegends = 'sr:sport:110';

const notDefaultMarkets = [
    soccer,
    basketball,
    baseball,
    tennis,
    volleyball,
    handball,
    iceHockey,
    tableTennis,
    americanFootball,
    rugby,
    mma,
    cricket,
    counterStrike,
    dota,
    leagueOfLegends,
];

// names
const fullTime1x2 = gettext('1x2 Full Time');
const total = gettext('Total');
const totalFT = gettext('Total (incl. overtime)');
const totalHT = gettext('Total - 1st half');
const totalQuarter = gettext('Total - quarter');
const totalTeam1 = gettext('Total - team 1');
const totalTeam2 = gettext('Total - team 2');
const totalGames = gettext('Total games');
const totalPoints = gettext('Total points');
const totalPointsSet = gettext('Total points - set');
const totalMaps = gettext('Total maps');
const totalInningsHome = gettext('Total innings - team 1');
const totalInningsAway = gettext('Total innings - team 2');
const totalInningsOverHome = gettext('Total team 1 - innings over N');
const totalInningsOverAway = gettext('Total team 2 - innings over N');
const totalInningsOver0toNHome = gettext('Total team 1 - innings overs 0 to N');
const totalInningsOver0ToNAway = gettext('Total team 2 - innings overs 0 to N');
const totalSixes = gettext('Total sixes');
const handicap = gettext('Handicap');
const handicapFT = gettext('Handicap (incl. overtime)');
const handicapSet = gettext('Set handicap');
const handicapGames = gettext('Game handicap');
const handicapPoint = gettext('Point handicap');
const handicapPointSet = gettext('Point handicap - set');
const handicapMap = gettext('Map handicap');
const bothTeamsToScore = gettext('Both teams to score');
const doubleChance = gettext('Double Chance');
const winner = gettext('Winner');
const winnerInclOT = gettext('Winner (incl. overtime)');
const setWinner = gettext('Winner - set');
const gameWinner = gettext('Winner - game');
const player1ToWinASet = gettext('Player 1 to win a set');
const player2ToWinASet = gettext('Player 2 to win a set');
const drawNoBet = gettext('Draw no bet');
const nextGoal = gettext('Next Goal');
// @TODO short name
const willTheFightGoTheDistance = gettext('Will the fight go the distance');

// market group ids
export const marketGroup1x2 = '1x2';
export const marketGroup1x2HT = '1x2-ht';
export const marketGroupTotalFt = 'total-ft';
export const marketGroupWinner = 'winner';
export const marketGroupTotal = 'total';
export const marketGroupTotalQuarter = 'total-quarter';
export const marketGroupTotalMaps = 'total-maps';
export const marketGroupSetWinner = 'set-winner';
export const marketGroupHandicap = 'handicap';
export const marketGroupHandicapFt = 'handicap-ft';
export const marketGroupHandicapHt = 'handicap-ht';
export const marketGroupHandicapSet = 'handicap-set';
export const marketGroupHandicapPoins = 'handicap-poins';
export const marketGroupHandicapMaps = 'handicap-maps';
export const marketGroupTotalGames = 'total-games';
export const marketGroupTotalPointsSet = 'total-points-set';
export const marketGroupDrawNoBet = 'draw-no-bet';
export const marketGroupWinnerOt = 'winner-ot';
export const marketGroupGameWinner = 'game-winner';
export const marketGroupTotalHt = 'total-ht';
export const marketGroupHandicapGames = 'handicap-games';
export const marketGroupHandicapPoinsSet = 'handicap-poins-set';
export const marketGroupTotalPoints = 'total-points';
export const marketGroupTotalTeam1 = 'total-team1';
export const marketGroupTotalTeam2 = 'total-team2';
export const marketGroupTotalTeam1ExtraInnings = 'total-team1-extra-innings';
export const marketGroupTotalTeam2ExtraInnings = 'total-team2-extra-innings';
export const marketGroupTotalInningsOverHome = 'total-innings-over-home';
export const marketGroupTotalInningsOverAway = 'total-innings-over-away';
export const marketGroupTotalInningsOver0ToNHome = 'total-innings-over-0-to-n-home';
export const marketGroupTotalInningsOver0ToNAway = 'total-innings-over-0-to-n-away';
export const marketGroupTotalInningsHome = 'total-innings-home';
export const marketGroupTotalInningsAway = 'total-innings-away';
export const marketGroupTotalSixes = 'total-sixes';
export const marketGroupDoubleChance = 'double-chance';
export const marketGroupNextGoal = 'next-goal';
export const marketGroupBothTeamsToScore = 'both-teams-to-score';
export const marketGroupPlayer1Set = 'player1-set';
export const marketGroupPlayer2Set = 'player2-set';
export const marketGroupWillFightDistance = 'will-fight-distance';

// outcomes titles
export const outcomes1x2 = ['1', 'X', '2'];
export const outcomesWinner = ['1', '2'];
export const outcomesTotal = [gettext('T:O'), gettext('T:U')];
export const outcomesHandicap = ['1', '2'];
export const outcomesDoubleChance = ['1X', '12', 'X2'];
export const outcomesNextGoal = ['1', gettext('NG'), '2'];
export const outcomeToWinASet = [gettext('Yes'), gettext('No')];
export const outcomesBothTeamsToScore = [gettext('Yes'), gettext('No')];
export const outcomesWillFightDistance = [gettext('Yes'), gettext('No')];


export const otherwiseGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        excludeSportIds: notDefaultMarkets,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [186, 219, 251, 340, 406, 4],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        excludeSportIds: notDefaultMarkets,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18, 225, 258],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        excludeSportIds: notDefaultMarkets,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [16, 187, 223, 237, 256],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        excludeSportIds: notDefaultMarkets,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 70,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupDoubleChance,
        name: doubleChance,
        type: EMarketGroupType.doubleChance,
        externalIds: [10],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        excludeSportIds: notDefaultMarkets,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 60,
        },
        outcomesTitles: outcomesDoubleChance,
    },
];

export const soccerGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupBothTeamsToScore,
        name: bothTeamsToScore,
        type: EMarketGroupType.results,
        externalIds: [29],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
        },
        outcomesTitles: outcomesBothTeamsToScore,
    },
    {
        id: marketGroupDoubleChance,
        name: doubleChance,
        type: EMarketGroupType.doubleChance,
        externalIds: [10],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 70,
        },
        outcomesTitles: outcomesDoubleChance,
    },
    {
        id: marketGroupTotalHt,
        name: totalHT,
        type: EMarketGroupType.totals,
        externalIds: [68],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 70,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalTeam1,
        name: totalTeam1,
        type: EMarketGroupType.totals,
        externalIds: [19],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 60,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalTeam2,
        name: totalTeam2,
        type: EMarketGroupType.totals,
        externalIds: [20],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 50,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupNextGoal,
        name: nextGoal,
        type: EMarketGroupType.results,
        externalIds: [8],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 40,
        },
        outcomesTitles: outcomesNextGoal,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [16],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [soccer],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 30,
        },
        outcomesTitles: outcomesHandicap,
    },
];

export const baseballGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [251],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [baseball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [258],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [baseball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [256],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [baseball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotalTeam1ExtraInnings,
        name: totalTeam1,
        type: EMarketGroupType.handicap,
        externalIds: [260],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [baseball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 70,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalTeam2ExtraInnings,
        name: totalTeam2,
        type: EMarketGroupType.handicap,
        externalIds: [261],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [baseball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 60,
        },
        outcomesTitles: outcomesTotal,
    },
];

export const cricketGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [340],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotalInningsOverHome,
        name: totalInningsOverHome,
        type: EMarketGroupType.totals,
        externalIds: [356],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 90,
            [EMarketGroupConfigType.LIVE]: 50,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalInningsOverAway,
        name: totalInningsOverAway,
        type: EMarketGroupType.totals,
        externalIds: [357],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
            [EMarketGroupConfigType.LIVE]: 40,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalInningsOver0ToNHome,
        name: totalInningsOver0toNHome,
        type: EMarketGroupType.totals,
        externalIds: [352],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 70,
            [EMarketGroupConfigType.LIVE]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalInningsOver0ToNAway,
        name: totalInningsOver0ToNAway,
        type: EMarketGroupType.totals,
        externalIds: [353],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 60,
            [EMarketGroupConfigType.LIVE]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalSixes,
        name: totalSixes,
        type: EMarketGroupType.totals,
        externalIds: [640],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 50,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalInningsHome,
        name: totalInningsHome,
        type: EMarketGroupType.totals,
        externalIds: [606],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.ALL]: 70,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalInningsAway,
        name: totalInningsAway,
        type: EMarketGroupType.totals,
        externalIds: [607],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [cricket],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.ALL]: 60,
        },
        outcomesTitles: outcomesTotal,
    },
];

export const basketballGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [219],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 100,
            [EMarketGroupConfigType.LIVE]: 60,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [16],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 70,
            [EMarketGroupConfigType.LIVE]: 50,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupTotalFt,
        name: totalFT,
        type: EMarketGroupType.totals,
        externalIds: [225],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 100,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupTotalQuarter,
        name: totalQuarter,
        type: EMarketGroupType.totals,
        externalIds: [236],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupHandicapFt,
        name: handicapFT,
        type: EMarketGroupType.handicap,
        externalIds: [223],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [basketball],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 80,
        },
        outcomesTitles: outcomesHandicap,
    },
];

export const tennisGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [186],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupHandicapSet,
        name: handicapSet,
        type: EMarketGroupType.handicap,
        externalIds: [188],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 90,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupPlayer1Set,
        name: player1ToWinASet,
        type: EMarketGroupType.results,
        externalIds: [192],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
        },
        outcomesTitles: outcomeToWinASet,
    },
    {
        id: marketGroupPlayer2Set,
        name: player2ToWinASet,
        type: EMarketGroupType.results,
        externalIds: [193],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 70,
        },
        outcomesTitles: outcomeToWinASet,
    },
    {
        id: marketGroupSetWinner,
        name: setWinner,
        type: EMarketGroupType.results,
        externalIds: [202],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 90,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotalGames,
        name: totalGames,
        type: EMarketGroupType.totals,
        externalIds: [189],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupHandicapGames,
        name: handicapGames,
        type: EMarketGroupType.handicap,
        externalIds: [187],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tennis],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 70,
        },
        outcomesTitles: outcomesHandicap,
    },
];

export const volleyballGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [186],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [volleyball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupHandicapPoins,
        name: handicapPoint,
        type: EMarketGroupType.handicap,
        externalIds: [237],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [volleyball],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 90,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotalPoints,
        name: totalPoints,
        type: EMarketGroupType.totals,
        externalIds: [238],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [volleyball],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    // live + winner
    {
        id: marketGroupSetWinner,
        name: setWinner,
        type: EMarketGroupType.results,
        externalIds: [202],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [volleyball],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 90,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotalPointsSet,
        name: totalPointsSet,
        type: EMarketGroupType.totals,
        externalIds: [310],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [volleyball],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupHandicapPoinsSet,
        name: handicapPointSet,
        type: EMarketGroupType.handicap,
        externalIds: [309],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [volleyball],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 70,
        },
        outcomesTitles: outcomesHandicap,
    },
];

export const handballGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [handball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [handball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupDrawNoBet,
        name: drawNoBet,
        type: EMarketGroupType.results,
        externalIds: [11],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [handball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [16],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [handball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 70,
        },
        outcomesTitles: outcomesHandicap,
    },
];

export const iceHockeyGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [iceHockey],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [iceHockey],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupWinnerOt,
        name: winnerInclOT,
        type: EMarketGroupType.results,
        externalIds: [406],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [iceHockey],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesWinner,
    },
    // live 18, 1, 406, 16
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [16],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [iceHockey],
        configType: EMarketGroupConfigType.LIVE,
        weight: {
            [EMarketGroupConfigType.LIVE]: 70,
        },
        outcomesTitles: outcomesHandicap,
    },
];

export const tableTennisGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [186],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tableTennis],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [237],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [tableTennis],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupGameWinner,
        name: gameWinner,
        type: EMarketGroupType.results,
        externalIds: [245],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [tableTennis],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupTotalPoints,
        name: totalPoints,
        type: EMarketGroupType.totals,
        externalIds: [238],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [tableTennis],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 70,
        },
        outcomesTitles: outcomesTotal,
    },
];

export const americanFootballGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupHandicapFt,
        name: handicapFT,
        type: EMarketGroupType.handicap,
        externalIds: [223],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [americanFootball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 100,
            [EMarketGroupConfigType.LIVE]: 60
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotalFt,
        name: totalFT,
        type: EMarketGroupType.totals,
        externalIds: [225],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [americanFootball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 90,
            [EMarketGroupConfigType.LIVE]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [219],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [americanFootball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
            [EMarketGroupConfigType.LIVE]: 90,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [americanFootball],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 60,
            [EMarketGroupConfigType.LIVE]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
];

export const rugbyGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [rugby],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupHandicap,
        name: handicap,
        type: EMarketGroupType.handicap,
        externalIds: [16],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [rugby],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 90,
            [EMarketGroupConfigType.LIVE]: 80,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [rugby],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 80,
            [EMarketGroupConfigType.LIVE]: 90,
        },
        outcomesTitles: outcomesTotal,
    },
];

export const mmaGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [186],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [mma],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroup1x2,
        name: fullTime1x2,
        type: EMarketGroupType.results,
        externalIds: [1],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [mma],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupTotal,
        name: total,
        type: EMarketGroupType.totals,
        externalIds: [18],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [mma],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroupWillFightDistance,
        name: willTheFightGoTheDistance,
        type: EMarketGroupType.results,
        externalIds: [911],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [mma],
        configType: EMarketGroupConfigType.PREMATCH,
        weight: {
            [EMarketGroupConfigType.PREMATCH]: 70,
        },
        outcomesTitles: outcomesWillFightDistance,
    },
];

export const esportGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroupWinner,
        name: winner,
        type: EMarketGroupType.results,
        externalIds: [186, 219, 251, 340, 406, 4],
        maxRowItems: 2,
        canShowTeamNumbers: true,
        useAsDefault: true,
        includeSportIds: [counterStrike, dota, leagueOfLegends],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomesWinner,
    },
    {
        id: marketGroupHandicapMaps,
        name: handicapMap,
        type: EMarketGroupType.handicap,
        externalIds: [327],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [counterStrike, dota, leagueOfLegends],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotalMaps,
        name: totalMaps,
        type: EMarketGroupType.totals,
        externalIds: [328],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        includeSportIds: [counterStrike, dota, leagueOfLegends],
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesTotal,
    }
];

export const allGroups: IMarketGroupConfigExtended[] = [
    ...soccerGroups,
    ...basketballGroups,
    ...baseballGroups,
    ...tennisGroups,
    ...volleyballGroups,
    ...handballGroups,
    ...iceHockeyGroups,
    ...tableTennisGroups,
    ...americanFootballGroups,
    ...rugbyGroups,
    ...mmaGroups,
    ...cricketGroups,
    ...otherwiseGroups,
    ...esportGroups,
];

export const asianGroups: IMarketGroupConfigExtended[] = [
    {
        id: marketGroup1x2,
        name: gettext('FT 1X2'),
        type: EMarketGroupType.results,
        externalIds: [1, 4, 186, 219, 251, 340, 406],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 100,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupHandicap,
        name: gettext('FT HDP'),
        type: EMarketGroupType.handicap,
        externalIds: [16, 187, 223, 237, 256],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 90,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotal,
        name: gettext('FT O/U'),
        type: EMarketGroupType.totals,
        externalIds: [18, 189, 225, 238, 258],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 80,
        },
        outcomesTitles: outcomesTotal,
    },
    {
        id: marketGroup1x2HT,
        name: gettext('HT 1X2'),
        type: EMarketGroupType.results,
        externalIds: [60, 83],
        maxRowItems: 3,
        canShowTeamNumbers: true,
        useAsDefault: true,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 70,
        },
        outcomesTitles: outcomes1x2,
    },
    {
        id: marketGroupHandicapHt,
        name: gettext('HT HDP'),
        type: EMarketGroupType.handicap,
        externalIds: [66, 88],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 60,
        },
        outcomesTitles: outcomesHandicap,
    },
    {
        id: marketGroupTotalHt,
        name: gettext('HT O/U'),
        type: EMarketGroupType.totals,
        externalIds: [90, 68],
        maxRowItems: 2,
        canShowTeamNumbers: false,
        useAsDefault: true,
        configType: EMarketGroupConfigType.ALL,
        weight: {
            [EMarketGroupConfigType.ALL]: 50,
        },
        outcomesTitles: outcomesTotal,
    },
];

